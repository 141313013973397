export default {
  SETTINGS_SECTION: '/settings',
  ACCOUNT_DETAILS: '/settings/account-details',
  ENTITIES_AND_USERS: '/settings/entites-and-users',
  USERS: '/settings/users',
  ENTITIES: '/settings/entities',
  BILLING_AND_PAYMENTS: '/settings/billing-and-payments',
  SUPPORT_AND_FAQ: '/settings/support-and-faq',
  CONTACT_US: '/contact-us',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  RESET_PASSWORD: '/reset-password',
  CREATE_NEW_PASSWORD: '/create-new-password',
  HOME: '/',
  DASHBOARD: '/dashboard',
  WAREHOUSE: '/warehouse',
  REPORT: '/warehouse/report',
  SHOW_PRODUCT: '/warehouse/product/:id',
  ADD_PRODUCT: '/warehouse/add-product',
  ORDERS: '/orders',
  FAILED_ORDERS: '/failed-orders',
  PLACE_ORDER: '/orders/place-order',
  PLACE_PRODUCT_ORDER: '/orders/place-order/product/:productId',
  PLACE_CUSTOMER_ORDER: '/orders/place-order/customer/:customerId',
  EDIT_ORDER: '/orders/:orderId',
  FINANCIALS: '/financials',
  CUSTOMERS: '/customers',
  VERSION: '/version',
  VERIFY: '/verify',
};
