import React from 'react';
import { FormattedNumber, IntlShape } from 'react-intl';
import { ColumnDef } from '@tanstack/react-table';

import { GetOrdersResponseSingleItem } from '../../setup/apiTypes/orders';
import { makeDateCellRenderer } from '../../utils/tableRenderers';
import {
  StyledFailedAttemptsList,
  StyledFailedAttemptsListContent,
  StyledFailedAttemptsListLabel,
} from '../../components/organisms/Modal/templates/OrderSummaryModal/styles';
import Typography from '../../components/styles/Typography';
import { FailedAttemptsItem } from '../../components/organisms/Modal/templates/OrderSummaryModal/types';

export const ORDER_STATUS_ALL = 'all';

export const ordersTableColumns = (
  intl: IntlShape
): ColumnDef<GetOrdersResponseSingleItem>[] => [
  {
    header: intl.formatMessage({
      id: 'orders.customerName',
      defaultMessage: 'Customer Name',
    }),
    accessorKey: 'name',
  },
  {
    header: intl.formatMessage({
      id: 'orders.date',
      defaultMessage: 'Date',
    }),
    accessorKey: 'createdAt',
    cell: makeDateCellRenderer(),
  },
  {
    header: intl.formatMessage({
      id: 'orders.lastUpdated',
      defaultMessage: 'Last Updated',
    }),
    accessorKey: 'lastUpdatedAt',
    cell: makeDateCellRenderer(),
  },
  {
    header: intl.formatMessage({
      id: 'orders.customerPhone',
      defaultMessage: 'Customer Phone',
    }),
    accessorKey: 'phoneNumber',
  },
  {
    header: intl.formatMessage({
      id: 'orders.city',
      defaultMessage: 'City',
    }),
    accessorKey: 'city',
  },
  {
    header: intl.formatMessage({
      id: 'orders.receiptNo',
      defaultMessage: 'Receipt No.',
    }),
    accessorKey: 'receiptNumber',
  },
  {
    header: intl.formatMessage({
      id: 'orders.valueIQD',
      defaultMessage: 'Value IQD',
    }),
    accessorKey: 'value',
    cell: ({ cell: { getValue } }) => (
      <FormattedNumber value={getValue() as number} />
    ),
  },
  {
    header: intl.formatMessage({
      id: 'failedAttempts',
      defaultMessage: 'Failed Attempts',
    }),
    accessorKey: 'failedAttempts',
    cell: ({ cell: { getValue } }) => {
      const failedAttempts = getValue<FailedAttemptsItem[]>() || [];

      if (!failedAttempts.length) return '—';

      return (
        <>
          {failedAttempts.map(({ attemptNumber, reason, reasonCode }) => (
            <StyledFailedAttemptsList {...{ key: reasonCode }} noBorder>
              {/* Attempt Number */}
              <Typography>
                {intl.formatMessage({ id: 'attemptNumber' })}:
              </Typography>
              <StyledFailedAttemptsListContent>
                {attemptNumber}
              </StyledFailedAttemptsListContent>

              {/* Reason Code */}
              <StyledFailedAttemptsListLabel>
                {intl.formatMessage({ id: 'reasonCode' })}:
              </StyledFailedAttemptsListLabel>
              <StyledFailedAttemptsListContent>
                {reasonCode}
              </StyledFailedAttemptsListContent>
              {/* Reason */}

              <StyledFailedAttemptsListLabel>
                {intl.formatMessage({ id: 'reason' })}:
              </StyledFailedAttemptsListLabel>
              <StyledFailedAttemptsListContent>
                {reason}
              </StyledFailedAttemptsListContent>
            </StyledFailedAttemptsList>
          ))}
        </>
      );
    },
  },
];
