import styled, { css } from 'styled-components';
import { variant } from 'styled-system';
import { textStyles } from '../../../../../theme/config/typography';
import Typography from '../../../../styles/Typography';

export const StyledOrderSummaryHeader = styled.div`
  top: 0;
  z-index: ${({ theme }) => theme.zindexes.stickyHeader};
  background-color: ${({ theme }) => theme.colors.background.default};

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 3rem;
    background-color: ${({ theme }) => theme.colors.background.default};
    top: 0;
    left: 0;
    transform: translateY(-100%);
  }
`;

export const StyledOrderSummaryModalAction = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`;

export const StyledOrderSummaryModalTableWrapper = styled.div`
  position: relative;
`;

export const StyledOrderSummaryModalTableExpandButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    top: 50%;
    left: 0;
    background-color: ${({ theme }) =>
      theme.colors.orderSummaryModalTableExpandButton.border};
  }
`;

export const StyledOrderSummaryModalTableExpandButton = styled.button.attrs({
  type: 'button',
  variant: 'caption',
})`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 1.75rem;
  padding: 0 1.125rem;
  color: ${({ theme }) =>
    theme.colors.orderSummaryModalTableExpandButton.color};
  background-color: ${({ theme }) =>
    theme.colors.orderSummaryModalTableExpandButton.background};
  border: 1px solid
    ${({ theme }) => theme.colors.orderSummaryModalTableExpandButton.border};
  border-radius: 1.875rem;
  outline: none;
  cursor: pointer;
  ${variant({
    variants: {
      ...textStyles,
    },
  })};
`;

export const StyledOrderSummaryModalTotalsWrapper = styled.div`
  padding: 3.5rem 0 3rem;
`;

export const StyledOrderSummaryModalContactInfoWrapper = styled.div`
  padding: 2.75rem 0;
  border-top: 1px solid
    ${({ theme }) => theme.colors.orderSummaryModalContactInfoWrapper.border};
`;

export const StyledOrderSummaryModalContactInfoInner = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

export const StyledOrderSummaryMapWrapper = styled.div`
  width: ${({ theme }) => theme.dimensions.googleMap.width};
  height: ${({ theme }) => theme.dimensions.googleMap.height};
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: ${({ theme }) => theme.shadows.small};
  ${({ theme }) =>
    theme.direction === 'rtl'
      ? css`
          margin-right: 1.5rem;
        `
      : css`
          margin-left: 1.5rem;
        `};
`;

export const StyledOrderIdLabel = styled(Typography)`
  &::after {
    content: ':';
  }

  margin-inline-end: 0.5em;
`;

export const StyledFailedAttemptsList = styled.dl<{ noBorder?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: flex-start;
  grid-gap: 0.5rem 2.75rem;
  padding: 0.75rem 0;
  border-bottom: 1px solid
    ${({ theme, noBorder }) =>
      noBorder
        ? 'none'
        : theme.colors.orderSummaryModalContactInfoWrapper.border};
`;

export const StyledFailedAttemptsListLabel = styled.dt`
  padding-top: 2px;
`;

export const StyledFailedAttemptsListContent = styled.dd.attrs({
  variant: 'bodyBold',
})`
  ${variant({
    variants: {
      ...textStyles,
    },
  })};
`;
