import axios from 'axios';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useToasts } from 'react-toast-notifications';
import qs from 'qs';
import { makeApiUrl } from '../../utils/reactQuery/index';

import { FailedOrder } from '../../assets/svg/icons';
import apiRoutes from '../../setup/consts/apiRoutes';
import { OrdersGridFilters, UsePlaceholderPropsReturnType } from './types';
import { downloadFile } from '../../utils';
import { formatDateDashes } from '../../components/atoms/Fields/Datepicker/utils';

export const usePlaceholderProps = (): UsePlaceholderPropsReturnType => {
  const intl = useIntl();

  const title = intl.formatMessage({
    id: 'orders.noFailedOrders',
    defaultMessage: 'No Failed Orders',
  });

  const description = intl.formatMessage({
    id: 'orders.failedOrdersPlaceholderDescription',
    defaultMessage: 'Failed Orders Placeholder Description',
  });

  const Icon = FailedOrder;

  return {
    title,
    description,
    Icon,
  };
};

export const useExportOrders = (): {
  isLoading: boolean;
  handleDownloadOrdersExcelFile: (filters: OrdersGridFilters) => Promise<any>;
} => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { addToast } = useToasts();
  const intl = useIntl();

  const handleDownloadOrdersExcelFile = async (filters: OrdersGridFilters) => {
    try {
      setIsLoading(true);

      const res = await axios
        .get(makeApiUrl(apiRoutes.ORDERS_EXPORT), {
          params: filters,
          paramsSerializer: (params) => qs.stringify(params),
          responseType: 'blob',
        })
        .finally(() => setIsLoading(false));

      if (res.status === 200 && res.data) {
        const file = new Blob([res.data]);

        downloadFile(file, `orders - ${formatDateDashes(new Date())}.xlsx`);
      } else {
        addToast(intl.formatMessage({ id: 'failed' }), {
          appearance: 'error',
        });
      }
    } catch (error) {
      addToast(intl.formatMessage({ id: 'failed' }), {
        appearance: 'error',
      });
    }
  };

  return {
    isLoading,
    handleDownloadOrdersExcelFile,
  };
};
