import React from 'react';
import { Redirect } from 'react-router-dom';

import SettingsLayout from '../../components/layouts/SettingsLayout';
import LoginLayout from '../../components/layouts/LoginLayout';
import SignInPage from '../login/sign-in';
import SignUpPage from '../login/sign-up';
import VerifyPage from '../login/verify';
import routes from '../../setup/consts/routes';
import EntitiesAndUsersPage from '../settings/entitiesAndUsers';
import SupportAndFaqPage from '../settings/supportAndFaq';
import { AppRoute } from './types';
import { AuthAccessibility } from './enums';
import ResetPasswordPage from '../login/reset-password';
import CotactUsPage from '../settings/contact-us';
import BillingAndPaymentsPage from '../settings/billingAndPayments';
import AccountDetailsPage from '../settings/accountDetails';
import MainLayout from '../../components/layouts/MainLayout';
import DashboardPage from '../index';
import WarehousePage from '../warehouse';
import ProductFormPage from '../warehouse/productFormPage';
import ProductPage from '../warehouse/showProduct';
import ReportPage from '../warehouse/report';
import FinancialsPage from '../financials';
import CustomerPage from '../customers';
import OrdersPage from '../orders';
import OrderFormPage from '../orders/orderFormPage';
import Version from '../version';
import { UserViewPermissions } from '../../components/layouts/MainLayout/consts';
import FailedOrdersPage from '../failed-orders';

const {
  ACCOUNT_DETAILS,
  ENTITIES,
  USERS,
  BILLING_AND_PAYMENTS,
  SUPPORT_AND_FAQ,
  CONTACT_US,
  SIGN_IN,
  VERIFY,
  SIGN_UP,
  RESET_PASSWORD,
  DASHBOARD,
  WAREHOUSE,
  ADD_PRODUCT,
  SHOW_PRODUCT,
  REPORT,
  ORDERS,
  FAILED_ORDERS,
  PLACE_ORDER,
  PLACE_PRODUCT_ORDER,
  FINANCIALS,
  CUSTOMERS,
  HOME,
  EDIT_ORDER,
  PLACE_CUSTOMER_ORDER,
  VERSION,
} = routes;

export const getAppRoutes = ({
  isAdvancedUser,
  isSandoogUser,
}: {
  isAdvancedUser: boolean;
  isSandoogUser: boolean;
}): AppRoute[] => [
  {
    path: ACCOUNT_DETAILS,
    component: AccountDetailsPage,
    layout: SettingsLayout,
  },
  {
    path: ENTITIES,
    component: EntitiesAndUsersPage,
    layout: SettingsLayout,
    permission: UserViewPermissions.EntitiesUser,
  },
  {
    path: USERS,
    component: EntitiesAndUsersPage,
    layout: SettingsLayout,
    permission: UserViewPermissions.EntitiesUser,
  },
  {
    path: BILLING_AND_PAYMENTS,
    component: BillingAndPaymentsPage,
    layout: SettingsLayout,
    permission: UserViewPermissions.Settings,
  },
  {
    path: SUPPORT_AND_FAQ,
    component: SupportAndFaqPage,
    layout: SettingsLayout,
  },
  {
    path: CONTACT_US,
    component: CotactUsPage,
    layout: SettingsLayout,
  },
  {
    path: DASHBOARD,
    component: DashboardPage,
    layout: MainLayout,
    permission: UserViewPermissions.Dashboard,
  },
  ...(isAdvancedUser
    ? [
        {
          path: WAREHOUSE,
          component: WarehousePage,
          layout: MainLayout,
          permission: UserViewPermissions.Warehouse,
        },
      ]
    : []),
  {
    path: SHOW_PRODUCT,
    component: ProductPage,
    layout: MainLayout,
    permission: UserViewPermissions.Warehouse,
  },
  {
    path: ADD_PRODUCT,
    component: ProductFormPage,
    layout: MainLayout,
    permission: UserViewPermissions.Warehouse,
  },
  {
    path: REPORT,
    component: ReportPage,
    layout: MainLayout,
  },
  {
    path: ORDERS,
    component: OrdersPage,
    layout: MainLayout,
    permission: UserViewPermissions.Orders,
  },
  {
    path: FAILED_ORDERS,
    component: FailedOrdersPage,
    layout: MainLayout,
    permission: UserViewPermissions.Orders,
  },
  {
    path: PLACE_ORDER,
    component: OrderFormPage,
    layout: MainLayout,
    permission: UserViewPermissions.Orders,
  },
  {
    path: PLACE_PRODUCT_ORDER,
    component: OrderFormPage,
    layout: MainLayout,
    permission: UserViewPermissions.Orders,
  },
  {
    path: PLACE_CUSTOMER_ORDER,
    component: OrderFormPage,
    layout: MainLayout,
    permission: UserViewPermissions.Orders,
  },
  {
    path: EDIT_ORDER,
    component: OrderFormPage,
    layout: MainLayout,
    permission: UserViewPermissions.Orders,
  },
  {
    path: FINANCIALS,
    component: FinancialsPage,
    layout: MainLayout,
    permission: UserViewPermissions.Financials,
  },
  {
    path: CUSTOMERS,
    component: CustomerPage,
    layout: MainLayout,
    permission: UserViewPermissions.Customer,
  },
  {
    path: RESET_PASSWORD,
    component: ResetPasswordPage,
    layout: LoginLayout,
    authAccessibility: AuthAccessibility.onlyNotLoggedIn,
  },
  {
    path: SIGN_IN,
    component: SignInPage,
    layout: LoginLayout,
    authAccessibility: AuthAccessibility.onlyNotLoggedIn,
  },
  {
    path: VERIFY,
    component: VerifyPage,
    layout: LoginLayout,
    authAccessibility: AuthAccessibility.onlyNotLoggedIn,
  },
  {
    path: SIGN_UP,
    component: SignUpPage,
    layout: LoginLayout,
    authAccessibility: AuthAccessibility.onlyNotLoggedIn,
  },
  {
    path: HOME,
    component: (): ReactComponentReturnType => <Redirect to={SIGN_IN} />,
    authAccessibility: AuthAccessibility.onlyNotLoggedIn,
  },
  {
    path: VERSION,
    component: Version,
    authAccessibility: AuthAccessibility.all,
  },
];
